<template>
  <v-col class="px-0 py-0">
    <div class="sectionBackground">
      <success-snackbar
        v-if="showSuccessSnackbar"
        :snackbarText="snackbarText"
      />
      <p class="sectionTitle">Основні дані</p>
      <div class="sectionLine" />
      <v-col cols="6">
        <v-text-field
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Публічний ключ"
          placeholder="Введіть назву.."
          v-model="public_key.value"
          @input="isUpdatePublicKey = true"
        />
        <v-text-field
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Приватний ключ"
          placeholder="Введіть назву.."
          v-model="private_key.value"
          @input="isUpdatePrivateKey = true"
        />
        <v-text-field
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="URL результату"
          placeholder="Введіть назву.."
          v-model="result_url.value"
          @input="isUpdateResultURL = true"
        />
        <v-text-field
          outlined
          dense
          height="50px"
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Час для оплати"
          placeholder="Введіть назву.."
          v-model="expire_time.value"
          @input="isUpdateExpireTime = true"
        />
        <v-textarea
          outlined
          dense
          style="border-radius: 10px"
          color="#C0C0C0"
          background-color="#FCFCFC"
          label="Опис"
          placeholder="Введіть назву.."
          v-model="description.value"
          @input="isUpdateDescription = true"
        />
      </v-col>
    </div>
    <submit-button
      style="
        margin-top: 40px;
        height: 48px !important;
        font-size: 20px;
        font-weight: 700;
      "
      :disabled="isDisable"
      @click="saveLiqPaySetting"
      >Зберегти</submit-button
    >
    <v-row no-gutters justify="center" style="margin-top: 12px">
      <cancel-btn text="Скасувати" @click="cancelChanges" />
    </v-row>
  </v-col>
</template>

<script>
import settingsService from "../../../requests/Admin/settingsService";
import CancelBtn from "../../UI/Buttons/cancelBtn.vue";
import submitButton from "../../UI/Buttons/submitButton.vue";
import SuccessSnackbar from "../../UI/Snackbars/successSnackbar.vue";
export default {
  components: { submitButton, SuccessSnackbar, CancelBtn },
  data: () => ({
    public_key: "",
    private_key: "",
    description: "",
    result_url: "",
    expire_time: "",
    isUpdatePublicKey: false,
    isUpdatePrivateKey: false,
    isUpdateDescription: false,
    isUpdateResultURL: false,
    isUpdateExpireTime: false,
    showLoader: true,
    showSuccessSnackbar: false,
    snackbarText: "",
  }),
  mounted() {
    this.setLiqPaySettings();
  },
  methods: {
    async setLiqPaySettings() {
      await settingsService.getSettingListForAdmin().then((res) => {
        if (res.status == "Success") {
          this.public_key =
            res.data.filter(
              (setting) => setting.key == "liqpay_public_key"
            )?.[0] || "";
          this.private_key =
            res.data.filter(
              (setting) => setting.key == "liqpay_private_key"
            )?.[0] || "";
          this.description =
            res.data.filter(
              (setting) => setting.key == "liqpay_description"
            )?.[0] || "";
          this.result_url =
            res.data.filter(
              (setting) => setting.key == "liqpay_result_url"
            )?.[0] || "";
          this.expire_time =
            res.data.filter((setting) => setting.key == "expire_time")?.[0] ||
            "";
        }
      });
      this.showLoader = false;
    },
    saveLiqPaySetting() {
      if (this.isUpdatePublicKey) {
        let form = new FormData();
        form.append("key", this.public_key.key);
        form.append("value", this.public_key.value);
        form.append("type", this.public_key.type);
        form.append("can_delete", this.public_key.can_delete);
        form.append("is_hidden", this.public_key.is_hidden);
        this.updateLiqPaySetting(this.public_key.id, form);
      }
      if (this.isUpdatePrivateKey) {
        let form = new FormData();
        form.append("key", this.private_key.key);
        form.append("value", this.private_key.value);
        form.append("type", this.private_key.type);
        form.append("can_delete", this.private_key.can_delete);
        form.append("is_hidden", this.private_key.is_hidden);
        this.updateLiqPaySetting(this.private_key.id, form);
      }
      if (this.isUpdateDescription) {
        let form = new FormData();
        form.append("key", this.description.key);
        form.append("value", this.description.value);
        form.append("type", this.description.type);
        form.append("can_delete", this.description.can_delete);
        form.append("is_hidden", this.description.is_hidden);
        this.updateLiqPaySetting(this.description.id, form);
      }
      if (this.isUpdateResultURL) {
        let form = new FormData();
        form.append("key", this.result_url.key);
        form.append("value", this.result_url.value);
        form.append("type", this.result_url.type);
        form.append("can_delete", this.result_url.can_delete);
        form.append("is_hidden", this.result_url.is_hidden);
        this.updateLiqPaySetting(this.result_url.id, form);
      }
      if (this.isUpdateExpireTime) {
        let form = new FormData();
        form.append("key", this.expire_time.key);
        form.append("value", this.expire_time.value);
        form.append("type", this.expire_time.type);
        form.append("can_delete", this.expire_time.can_delete);
        form.append("is_hidden", this.expire_time.is_hidden);
        this.updateLiqPaySetting(this.expire_time.id, form);
      }
      this.snackbarText = "Дані успішно оновлено";
      this.showSuccessSnackbar = true;
    },
    async updateLiqPaySetting(id, form) {
      await settingsService.updateSetting(id, form);
    },
    cancelChanges() {
      this.isUpdatePublicKey = false;
      this.isUpdatePrivateKey = false;
      this.isUpdateDescription = false;
      this.isUpdateResultURL = false;
      this.isUpdateExpireTime = false;
      this.setLiqPaySettings();
    },
  },
  computed: {
    isDisable() {
      return (
        !this.isUpdatePublicKey &&
        !this.isUpdatePrivateKey &&
        !this.isUpdateExpireTime &&
        !this.isUpdateDescription
      );
    },
  },
};
</script>

<style>
</style>